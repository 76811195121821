<template>
<article id="page-newcontent">
 <Collection  :name="`case/${id}`"  v-slot="{ model }">
  <div class="breadcrumb">
    <ol class="w-1200 r m-auto">
      <li><a href="/" class="home">首页</a></li>
      <li><a>案件</a></li>
      <li><a href="javascript:;">详情</a></li>
    </ol>
  </div>
  <section class="container">
    <div class="row">
      <div class="content" id="printart">
        <h1 id="main_title">{{model.recase_title}}</h1>
        <div class="article-infos">
          <p> <span>发布时间：{{ moment(model.created).format('YYYY-MM-DD') }}</span> </p>
        </div>
        <section id="main">
          <div id="zoom" :class="'fsize'">
            <div v-if="model.recase_type === 'notary'">
              <h6>【代理意见】</h6>
              <div class="contentspan" v-html="model.recase_des"></div>
              <h6>公证书格式</h6>
              <div class="contentspan" v-html="model.noformat"></div>
            </div>
            <div v-else-if="model.recase_type === 'lawyer'">
              <h6>【案例背景】</h6>
            <div class="contentspan" v-html="model.noformat"></div>
            <h6>【代理意见】</h6>
            <div class="contentspan" v-html="model.recase_des"></div>
            <h6>【裁判文书】</h6>
            <div class="contentspan" v-html="model.introduce"></div>
            <h6>【判决结果】</h6>
            <div class="contentspan" v-html="model.recase_result"></div>
            <h6>【案例评析】</h6>
            <div class="contentspan" v-html="model.recase_comment"></div>
            <h6>【结语和建议】</h6>
            <div class="contentspan" v-html="model.suggest"></div>
            </div>
             <div v-else-if="model.recase_type === 'forensics'">
                <h6>【案情简介】</h6>
            <div class="contentspan" v-html="model.recase_des"></div>
            <h6>【鉴定过程】</h6>
            <div class="contentspan" v-html="model.introduce"></div>
            <h6>【分析说明】</h6>
            <div class="contentspan" v-html="model.recase_result"></div>
            <h6>【鉴定意见】</h6>
            <div class="contentspan" v-html="model.suggest"></div>
             </div>
              <div v-else-if="model.recase_type === 'forensics'">
                <h6>【案情简介】</h6>
            <div class="contentspan" v-html="model.recase_des"></div>
            <h6>【鉴定过程】</h6>
            <div class="contentspan" v-html="model.introduce"></div>
            <h6>【分析说明】</h6>
            <div class="contentspan" v-html="model.recase_result"></div>
            <h6>【鉴定意见】</h6>
            <div class="contentspan" v-html="model.suggest"></div>
             </div>
             <div v-else>
               <div class="contentspan" v-html="model.recase_des"></div>
             </div>

          </div>
        </section>
      </div>
    </div>
  </section>
  </Collection>
</article>
</template>
<script>
import Collection from '@/components/Collection'
export default {
  name: 'info',
  components: {
    Collection
  },
  data () {
    return {
      id: this.$route.params.id,
      fsize: 'size16px'
    }
  },
  methods: {
    setFontSize (fontSize) {
      this.fsize = fontSize
    }
  },
  activated () {
    this.id = this.$route.params.id
  }
}
</script>
<style scoped src="../../assets/css/case.css"></style>
<style scoped src="../../assets/css/article.css"></style>
